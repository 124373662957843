import {
  CollectorDetailsResponseDto,
  ConfirmRequisitionRequestDto,
  CreateOrderResponseDto,
  GenerateRequisitionPdfResponseDto,
  GenerateRequisitionRequestDto,
  SignRequisitionPdfDto,
  SubmitOrderRequestDto,
} from '@careos/toxicology-types';
import { ToxiHttpClient as ToxiClient } from './toxicology-client';

interface FetchResultPdfParams {
  requisitionId: string;
  reportType: 'chiral' | 'DoA' | 'PEth';
  locale: string;
}

const REQUISITIONS_URL = '/requisitions' as const;

export const api = {
  createRequisitionPdf: (body: GenerateRequisitionRequestDto) =>
    ToxiClient.post<GenerateRequisitionPdfResponseDto>(
      `${REQUISITIONS_URL}/generate-requisition`,
      body,
    ),

  createRequisition: (body: SubmitOrderRequestDto) =>
    ToxiClient.post<CreateOrderResponseDto>(`${REQUISITIONS_URL}/submit`, body),

  confirmRequisition: (body: ConfirmRequisitionRequestDto) =>
    ToxiClient.post<void>(`${REQUISITIONS_URL}/confirm-requisition`, body),

  signRequisition: (body: SignRequisitionPdfDto) =>
    ToxiClient.post<GenerateRequisitionPdfResponseDto>(
      `${REQUISITIONS_URL}/sign-requisition`,
      body,
    ),
  fetchRequisitionPdf: (requisitionId: string) =>
    ToxiClient.get<string | undefined>(
      `${REQUISITIONS_URL}/get-requisition-pdf/${requisitionId}`,
    ),

  fetchSignedConsentPdf: (requisitionId: string) =>
    ToxiClient.get<string | undefined>(
      `${REQUISITIONS_URL}/donor-signed-requisition-pdf/${requisitionId}`,
    ),

  fetchCollectorDetails: (requisitionId: string) =>
    ToxiClient.get<CollectorDetailsResponseDto>(
      `${REQUISITIONS_URL}/collector-details/${requisitionId}`,
    ),

  fetchResultPdf: ({
    requisitionId,
    reportType,
    locale,
  }: FetchResultPdfParams) =>
    ToxiClient.get<Blob>(
      `result-pdf/${requisitionId}/${reportType}/${locale}`,
      { responseType: 'blob' },
    ),
};
